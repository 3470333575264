import React from 'react';
import { SalesRankingData } from '../../entities';
import styles from '../../styles/components/views/SalesRankingView.module.scss';
import { price } from '../../utils';
import { Spinner } from '../layouts/Spinner';

interface Props {
  categoryName: string;
  data?: SalesRankingData[];
  loading: boolean;
}

export const SalesRankingView: React.FC<Props> = ({ categoryName, data, loading }) => {
  return <div className={styles.wrapper}>
    <div className={styles.header}>
      <p>{categoryName}売上ランキング</p>
    </div>
    <div className={styles.content}>
      {
        loading
          ? <Spinner loading size='contain'/>
          : <>
            <div className={styles.labels}>
              <p>順位</p>
              <p>{categoryName}</p>
              <p>売上</p>
            </div>
            <ul className={styles.ranking}>
              {data?.map((data, i) => (
                <li key={i}>
                  <p className={styles.rank}>{i + 1}</p>
                  <div className={styles.category}>
                    <p>{data.title}</p>
                    {data.subTitle && <p className={styles.category_subtitle}>{data.subTitle}</p>}
                  </div>
                  <p className={styles.sales}>{price(data.sales)}</p>
                </li>
              ))}
            </ul>
          </>
      }
    </div>
  </div>
};
