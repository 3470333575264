import Link from 'next/link';
import React from 'react';
import { PlatformTypeDecorator } from '../../decorators';
import { Brand, Platform } from '../../entities';
import styles from '../../styles/components/lists/BrandList.module.scss';

interface Props {
  brands: Brand[];
  platforms: Platform[];
  href: (brand: Brand) => string;
}

export const BrandList: React.FC<Props> = ({ brands, platforms, href }: Props) => (
  <ul className={styles.list}>
    {brands.map((brand) => (
      <li key={brand.id}>
        <Link href={href(brand)}>
          <a>
            <div className={styles.row}>
              <p>{brand.name}</p>
              <img src="/icon/right.svg" />
            </div>
            <div>
              {platforms
                .filter((platform) => platform.brandId === brand.id)
                .map((platform) => (
                  <img
                    src={new PlatformTypeDecorator(platform.type).icon()}
                    className={styles.platform}
                    key={platform.type}
                  />
                ))}
            </div>
          </a>
        </Link>
      </li>
    ))}
  </ul>
);
