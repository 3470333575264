import React from 'react';
import styles from '../../styles/components/buttons/TabButton.module.scss';

interface Props {
  items: string[];
  selectedItem: string;
  onSelect: (item: string) => void;
}

export const TabButton: React.FC<Props> = ({ items, selectedItem, onSelect }) => (
  <ul className={styles.wrapper}>
    {items.map((item, i) => (
      <li key={i} className={item === selectedItem ? styles.selected : ''}>
        <a onClick={() => onSelect(item)}>{item}</a>
      </li>
    ))}
  </ul>
);
