import React from 'react';
import Dropdown from 'react-dropdown';
import { Brand } from '../../entities';
import styles from '../../styles/components/controls/DropdownSelector.module.scss';

import 'react-dropdown/style.css';

interface Props {
  options: Pick<Brand, 'id' | 'name'>[];
  currentIndex: number;
  onSelect: (id: string) => void;
}

export const DropdownBrand: React.FC<Props> = (props: Props) => {
  return (
    <Dropdown
      options={props.options.map(option => ({ label: option.name, value: option.id }))}
      value={props.currentIndex !== undefined ? props.currentIndex === -1 ? props.options[0]?.name : props.options[props.currentIndex]?.name : undefined}
      onChange={option => props.onSelect(option.value)}
      arrowClosed={<span className={styles.arrow}><img src='/icon/down.svg' /></span>}
      arrowOpen={<span className={styles.arrow}><img src='/icon/down.svg' /></span>}
      controlClassName={styles.control}
    />
  );
};
