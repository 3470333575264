import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { Spinner } from './Spinner';

type Props = {
  loading: boolean
  error: Error | undefined
  children: React.ReactNode
};

export const WithBlank = ({ loading, error, children }: Props): React.ReactElement => {
  useEffect(() => {
    if (error) {
      alert('エラーが発生しました');
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <>
      {children}
      {loading && <Spinner loading size='full'/>}
    </>
  );
};
