import { Platform } from '../entities';
import { PlatformTypeDecorator } from './PlatformTypeDecorator';

export class PlatformDecorator {
  private platform: Pick<Platform, 'type'>;

  constructor(platform: Pick<Platform, 'type'>) {
    this.platform = platform;
  }

  name(): string {
    return new PlatformTypeDecorator(this.platform.type).name()
  }

  icon(): string {
    return new PlatformTypeDecorator(this.platform.type).icon()
  }
}
