import { Delivery, mapOrderPlatformTypeToDeliveryPlatformType } from '../entities';
import { DeliveryStatusDecorator } from './DeliveryStatusDecorator';

export class DeliveryDecorator {
  private delivery: Delivery;

  constructor(delivery: Delivery) {
    this.delivery = delivery;
  }

  courierTypeName(): string {
    return {
      inhouse: '自社配達',
      deaas: 'DeaaS',
      platform: 'プラットフォーム'
    }[this.delivery.courierType];
  }

  statusName(): string {
    return new DeliveryStatusDecorator(this.delivery.status).name()
  }

  platformName(): string {
    const deaasDeliveryPlatformTypeNames = {
      anycarry: 'エニキャリ'
    } as const;

    const defaultDeliveryPlatformTypeNames = {
      boteats: 'Bot Eats',
      demaecan: '出前館',
      menu: 'menu',
      ubereats: 'Uber Eats',
      wolt: 'Wolt'
    } as const;

    const names = {
      ...deaasDeliveryPlatformTypeNames,
      ...defaultDeliveryPlatformTypeNames
    }
    return names[mapOrderPlatformTypeToDeliveryPlatformType(this.delivery.platform)];
  }
}
