import { VFC } from 'react';
import ReactPaginate from 'react-paginate';
import styles from '../../styles/components/controls/Pagination.module.scss';

interface Props {
  currentPage: number;
  perPage: number;
  totalCount: number;
  onChange: (page: number) => void;
}

export const Pagination: VFC<Props> = (props: Props) => {
  const totalPage = Math.ceil(props.totalCount / props.perPage);
  const lowerCount = props.perPage * props.currentPage + 1;
  const upperCount = Math.min(props.perPage * (props.currentPage + 1), props.totalCount);

  return (
    <div className={styles.container}>
      <ReactPaginate
        pageCount={totalPage}
        breakLabel=''
        previousLabel=''
        nextLabel=''
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        containerClassName={styles.pagination}
        pageClassName={styles.item}
        activeClassName={styles.active}
        previousClassName={styles.prev}
        nextClassName={styles.next}
        disabledClassName={styles.disabled}
        onPageChange={({ selected }) => props.onChange(selected)}
      />
      <p className={styles.text}>{props.totalCount}件中{lowerCount}-{upperCount}件</p>
    </div>
  )
};
