import { PlatformType } from '../entities';

export class PlatformTypeDecorator {
  readonly platformType: PlatformType;

  constructor(platformType: PlatformType) {
    this.platformType = platformType;
  }

  name(): string {
    switch (this.platformType) {
      case 'boteats':
        return 'Bot Eats';
      case 'demaecan':
      case 'demaecanOfficial':
        return '出前館';
      case 'menu':
        return 'menu';
      case 'menuOfficial':
        return 'menu';
      case 'sukesan':
        return '資さんうどんモバイルオーダー'
      case 'ubereats':
        return 'Uber Eats';
      case 'ubereatsOfficial':
        return 'Uber Eats';
      case 'wolt':
        return 'Wolt'
      case 'woltOfficial':
        return 'Wolt';
    }
  }

  icon(): string {
    switch (this.platformType) {
      case 'boteats':
        return '/platform/boteats.png';
      case 'demaecan':
      case 'demaecanOfficial':
        return '/platform/demaecan.png';
      case 'menu':
        return '/platform/menu.png';
      case 'menuOfficial':
        return '/platform/menu.png';
      case 'sukesan':
        return '/platform/sukesan.png'
      case 'ubereats':
        return '/platform/ubereats.png';
      case 'ubereatsOfficial':
        return '/platform/ubereats.png';
      case 'wolt':
        return '/platform/wolt.png';
      case 'woltOfficial':
        return '/platform/wolt.png';
    }
  }
}
