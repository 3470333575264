import React from 'react';
import styles from '../../styles/components/views/CommentView.module.scss';

interface Props {
  text: string
}

export const CommentView: React.FC<Props> = ({ text }: Props) =>
  <span className={styles.comment}>
    <img src='/icon/comment.svg' className={styles.icon}/>{text}
  </span>
