import { DeliveryStatus } from '../entities';

export class DeliveryStatusDecorator {
  private deliveryStatus: DeliveryStatus;

  constructor(deliveryStatus: DeliveryStatus) {
    this.deliveryStatus = deliveryStatus;
  }

  name(): string {
    switch (this.deliveryStatus) {
      case 'created':
        return '配達依頼作成';
      case 'assigned':
        return '配達員確定'
      case 'picked':
        return 'ピックアップ完了'
      case 'delivered':
        return '配達完了'
      case 'canceled':
        return '配達キャンセル'
      case 'failed':
        return '配達失敗'
    }
  }
}
