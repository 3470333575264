import styles from '../../styles/components/buttons/TableButtonDownload.module.scss';

interface Props {
  onClick: () => void;
}

export const TableButtonDownload: React.FC<Props> = ({ onClick }: Props) => (
  <div className={styles.button} onClick={onClick}>
    <img src='/icon/download.svg' />
    <p>ダウンロード</p>
  </div>
);
