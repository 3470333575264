import { useCallback } from 'react';
import { calculateOrderItemPriceTotal, Order, OrderItem, OrderOption } from '../../entities'
import styles from '../../styles/components/views/OrderDetailsTableView.module.scss';
import * as Formatter from '../../utils';
import { If } from '../If';
import { AllergensView } from './AllergensView';
import { CommentView } from './CommentView';

interface Props {
  order: Order
}

interface OptionRowViewModel {
  option: OrderOption;
  shouldShowCategory: boolean
  isChildOption: boolean
  isGrandChildOption: boolean
  isLastItem: boolean
}

interface OptionGroupRowViewModel {
  option: OrderOption;
  shouldShowCategory: boolean
  isChildOption: boolean
  isGrandChildOption: boolean
  isLastItem: boolean
  optionGroups?: OptionGroupRowViewModel[];
}

type ItemRowsViewModel = {
  item: OrderItem;
  optionGroups: OptionGroupRowViewModel[];
}

export const OrderDetailsTableView: React.FC<Props> = ({ order }: Props) => {
  const { items } = buildOrderDetailsTableViewModel(order.items);

  const ItemOptionRow = useCallback(({ option, shouldShowCategory, isLastItem }: Pick<OptionRowViewModel, 'option' | 'shouldShowCategory' | 'isLastItem'>) =>
    <tr>
      <td>
        <div className={styles.order_option}>
          <span className={styles.spacing}/>
          <div className={isLastItem ? styles.option_item : styles.last_option_item}>
            <div className={styles.content}>
              {shouldShowCategory && <span className={styles.category}>{option.category}</span>}
              <div>
                {option.quantity > 1 && <span className={styles.quantity}>{option.quantity}×</span>}
                <span className={styles.title}>{option.title}</span>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td align='right'><span className={styles.item_pos_id}>{option.externalPosId}</span></td>
      <td align="right">
        <span className={styles.order_price}>{option.price && option.price > 0 ? Formatter.price(option.price) : ''}</span>
      </td>
      <td></td>
    </tr>
  , []);

  const ItemOptionChildRow = useCallback(({ option, shouldShowCategory, isLastItem }: Pick<OptionRowViewModel, 'option' | 'shouldShowCategory' | 'isLastItem'>) =>
    <tr>
      <td>
        <div className={styles.order_option}>
          <span className={styles.spacing}/>
          <div className={isLastItem ? styles.last_child_option_item : styles.child_option_item}>
            <div className={isLastItem ? styles.last_option_item : styles.option_item}>
              <div className={styles.content}>
                {shouldShowCategory && <span className={styles.category}>{option.category}</span>}
                <div>
                  {option.quantity > 1 && <span className={styles.quantity}>{option.quantity}×</span>}
                  <span className={styles.title}>{option.title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td align='right'><span className={styles.item_pos_id}>{option.externalPosId}</span></td>
      <td align="right">
        <span className={styles.order_price}>{option.price && option.price > 0 ? Formatter.price(option.price) : ''}</span>
      </td>
      <td></td>
    </tr>
  , []);

  const ItemOptionGrandChildRow: React.FC<Pick<OptionRowViewModel, 'option' | 'shouldShowCategory' | 'isLastItem'>>
     = useCallback(({ option, shouldShowCategory, isLastItem }: Pick<OptionRowViewModel, 'option' | 'shouldShowCategory' | 'isLastItem'>) => {
       return <tr>
         <td>s
           <div className={styles.order_option}>
             <span className={styles.spacing}/>
             <div className={isLastItem ? styles.last_child_option_item : styles.child_option_item}>
               <div className={isLastItem ? styles.last_child_option_item : styles.child_option_item}>
                 <div className={isLastItem ? styles.last_option_item : styles.option_item}>
                   <div className={styles.content}>
                     {shouldShowCategory && <span className={styles.category}>{option.category}</span>}
                     <div>
                       {option.quantity > 1 && <span className={styles.quantity}>{option.quantity}×</span>}
                       <span className={styles.title}>{option.title}</span>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </td>
         <td align='right'><span className={styles.item_pos_id}>{option.externalPosId}</span></td>
         <td align="right">
           <span className={styles.order_price}>{option.price && option.price > 0 ? Formatter.price(option.price) : ''}</span>
         </td>
         <td></td>
       </tr>
     }

     ,[]);

  const OptionRow: React.FC<OptionRowViewModel>
    = useCallback(({ option, shouldShowCategory, isChildOption, isGrandChildOption, isLastItem }: OptionRowViewModel) =>
      <>
        <If condition={isGrandChildOption}>
          <ItemOptionGrandChildRow {...{ option, shouldShowCategory, isLastItem }} />
        </If>
        <If condition={isChildOption}>
          <ItemOptionChildRow {...{ option, shouldShowCategory, isLastItem }} />
        </If>
        <If condition={!isChildOption && !isGrandChildOption}>
          <ItemOptionRow {...{ option, shouldShowCategory, isLastItem }} />
        </If>
      </>
    ,[ItemOptionChildRow, ItemOptionGrandChildRow, ItemOptionRow])

  const OptionGroupRow: React.FC<OptionGroupRowViewModel> 
    = useCallback(({ option, shouldShowCategory, optionGroups, isChildOption, isGrandChildOption, isLastItem }: OptionGroupRowViewModel) =>
      <>
        <OptionRow {...{ option, shouldShowCategory, isChildOption, isGrandChildOption, isLastItem }} />
        {
          optionGroups?.map(optionGroup => <OptionGroupRow {...optionGroup }/>)
        }
      </>
    , [OptionRow])

  const ItemRows: React.FC<ItemRowsViewModel> = useCallback(({ item, optionGroups }: ItemRowsViewModel) => (
    <>
      <tr>
        <td>
          <div className={styles.order_item}>
            <If condition={!item.options || item.options.length === 0}>
              <span className={styles.count_red}>{item.quantity}</span>
            </If>
            <If condition={item.options.length > 0}>
              <span className={styles.count_black}>{item.quantity}</span>
            </If>
            <span className={styles.order_item_title}>{item.title}</span>
          </div>
        </td>
        <td align='right'><span className={styles.item_pos_id}>{item.externalPosId}</span></td>
        <td align="right"><span className={styles.order_price}>{Formatter.price(item.price)}</span></td>
        <td align='right'><span className={styles.order_price_total}>{Formatter.price(calculateOrderItemPriceTotal(item))}</span></td>
      </tr>
      {optionGroups.map((optionGroup) => <OptionGroupRow {...optionGroup} key={`ItemRows-optionGroup-${optionGroup.option.id}`}/>)}
      <If condition={item.allergens}>
        <tr><td><div className={styles.vertical_spacing} /></td></tr>
        <tr>
          <td>
            <div className={styles.order_option}>
              <span className={styles.spacing}/>
              <AllergensView {...{ text: `アレルギー：${item.allergens?.map(({name }) => name).join('、')}`}}/>
            </div>
          </td>
          <td></td><td></td><td></td>
        </tr>
      </If>      
      <If condition={item.comment}>
        <tr><td><div className={styles.vertical_spacing} /></td></tr>
        <tr>
          <td>
            <div className={styles.order_option}>
              <span className={styles.spacing}/>
              <CommentView {...{ text: `商品へのコメント：${item.comment}`}}/>
            </div>
          </td>
          <td></td><td></td><td></td>
        </tr>
      </If>
    </>
  ), [OptionGroupRow])

  return <div className={styles.wrapper}>
    <table>
      <thead>
        <tr>
          <th align='left'>商品</th>
          <th align='left'>POS商品ID</th>
          <th align='right'>単価</th>
          <th align='right'>売上</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => <ItemRows {...item} key={`orderDetails-item-${item.item.uuid}`} />)}
      </tbody>
    </table>
  </div>
}

type OrderDetailsTableViewModel = {
  items: {
    item: OrderItem,
    optionGroups: {
      //1st level
      option: OrderOption
      shouldShowCategory: boolean
      isChildOption: boolean
      isGrandChildOption: boolean
      isLastItem: boolean
      optionGroups: {
        //2nd level
        option: OrderOption
        shouldShowCategory: boolean
        isChildOption: boolean
        isGrandChildOption: boolean
        isLastItem: boolean
        optionGroups: {
          //3rd level
          option: OrderOption
          shouldShowCategory: boolean
          isChildOption: boolean
          isGrandChildOption: boolean
          isLastItem: boolean
        }[]
      }[]
    }[]
  }[]
}

const buildOrderDetailsTableViewModel = (items: OrderItem[]): OrderDetailsTableViewModel => {
  const tree = items.map(item => ({
    item: item,
    optionGroups: item.options
      .filter(option => !option.parentId)
      .map((option, index) => ({
        //1st level options
        option: option,
        shouldShowCategory: index === 0,
        isChildOption: false,
        isGrandChildOption: false,
        isLastItem: false,
        optionGroups: item.options
          .filter(child => option.id && option.id === child.parentId)
          .map((child, index, childArray) => ({
            //2nd level options
            option: child,
            shouldShowCategory: index === 0,
            isChildOption: true,
            isGrandChildOption: false,
            isLastItem: item.options
              .filter(grand => grand.parentId === child.id).length === 0
              && childArray.length -1 === index,
            optionGroups: item.options
              .filter(grand => grand.parentId === child.id)
              .map((grand, index, grandArray) => ({
                //3rd level options
                option: grand,
                shouldShowCategory: index === 0,
                isChildOption: false,
                isGrandChildOption: true,
                isLastItem: grandArray.length -1 === index,
              }))
          }))
      }))
  }))
  return { items: tree };
}
