import { FileUpload, useFileUpload } from 'use-file-upload';
import styles from '../../styles/components/forms/InputFile.module.scss';

interface Props {
  title: string
  onChange: (data: File) => void
  message?: string
}

const InputFile: React.FC<Props> = (props: Props) => {
  const [file, selectFile] = useFileUpload();

  const onClick = () => {
    selectFile({ accept: 'text/csv', multiple: false }, file => {
      props.onChange((file as FileUpload).file);
    });
  };

  return (
    <div className={styles.wrapper}>
      <a className={styles.button} onClick={onClick}>
        <img src={'/icon/upload.svg'} />
        {file ? (file as FileUpload).name : props.title}
      </a>
      {props.message && <div className={styles.error}>{props.message}</div>}
    </div>
  );
};

export { InputFile };
