import Link from 'next/link';
import React from 'react';
import { IconButton, If } from '../../components';
import { Store } from '../../entities';
import styles from '../../styles/components/lists/StoreList.module.scss';

interface Props {
  stores: Store[];
  href: (store: Store) => string;
}

export const StoreList: React.FC<Props> = ({ stores, href }: Props) =>
  <>
    <If condition={!stores.length}>
      <div className={styles.emptyView}>
        <p className={styles.emptyText}>まだ店舗が登録されていません。</p>
        <IconButton title="店舗を追加" href="/stores/new" icon="add" />
      </div>
    </If>
    <If condition={stores.length}>
      <ul className={styles.list}>
        {stores.map(store => (
          <li key={`store-list-item-${store.id}`}>
            <Link href={href(store)}>
              <a>
                <p>{store.name}</p>
                <div className={styles.countWarning}>
                  <img src="/icon/right.svg" />
                </div>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </If>
  </>;
