import React from 'react';
import styles from '../../styles/components/layouts/Spinner.module.scss';

interface Props {
  loading: boolean;
  size: 'full' | 'contain'
}

const Spinner: React.FC<Props> = ({ loading, size }: Props) => {
  return (
    <>
      {loading && (
        size === 'full'
          ? <div className={styles.wrapper} data-testid='loading-spinner' >
            <div className={styles.spinner} />
          </div>
          : <div className={styles.wrapperContain} data-testid='loading-spinner'>
            <div className={styles.spinner} />
          </div>
      )}
    </>
  );
};

export { Spinner };
