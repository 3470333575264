import React from 'react';
import styles from '../../styles/components/forms/Radio.module.scss';

interface Props {
  title?: string
  options: {
    name: string
    value: string
  }[]
  onChange?: (_: string) => void
  value: string
  message?: string
}

export const Radio: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.warpper}>
      {props.title && <p className={styles.title}>{props.title}</p>}
      <fieldset className={styles.fieldset}>
        {props.options.map(option => (
          <a key={`Radio-option-${option.value}`}>
            <input
              className={styles.input}
              type='radio'
              checked={option.value === props.value}
              onChange={() => props.onChange?.(option.value)}
            />
            <label className={styles.label}>
              {option.name}
            </label>
          </a>
        ))}
      </fieldset>
      {props.message && <div className={styles.error}>{props.message}</div>}
    </div>
  );
};
