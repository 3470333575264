import React from 'react';
import Dropdown from 'react-dropdown';
import styles from '../../styles/components/controls/DropdownSelector.module.scss';

import 'react-dropdown/style.css';

interface Props {
  options: string[];
  placeholder: string;
  currentIndex: number;
  onSelect: (option: string) => void;
}

export const DropdownSelector: React.FC<Props> = (props: Props) => {
  return (
    <Dropdown
      options={props.options}
      value={props.currentIndex != undefined ? props.options[props.currentIndex] : undefined}
      placeholder={props.placeholder}
      onChange={(option) => {
        props.onSelect.call(this, option.value);
      }}
      className={styles.view}
    />
  );
};
