import React from 'react';
import styles from '../..//styles/components/controls/SegmentedControl.module.scss';

interface Props {
  options: string[];
  selectedIndex: number;
  onClick: (option: string) => void;
}

const SegmentedControl: React.FC<Props> = (props: Props) => {
  return (
    <ul className={styles.options}>
      {props.options.map((option, i) => (
        <li
          key={option}
          className={i === props.selectedIndex ? styles.selected : undefined}
          onClick={() => props.onClick(option)}>
          {option}
        </li>
      ))}
    </ul>
  );
};

export { SegmentedControl };
