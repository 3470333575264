import * as Formatter from '../../src/utils';
import { PlatformOperation, PlatformType } from '../entities';

export class PlatformOperationDecorator {
  private platformOperation: PlatformOperation;

  constructor(platform: PlatformOperation) {
    this.platformOperation = platform;
  }

  platformType(): PlatformType {
    return this.platformOperation.platformType;
  }

  operationType(): 'create' | 'delete' {
    switch (this.platformOperation.type) {
      case 'registration':
      case 'activation':
        return 'create';
      case 'deactivation':
      case 'deletion':
        return 'delete';
    }
  }

  operationText(): string {
    switch (this.platformOperation.type) {
      case 'registration': return '連携予定';
      case 'activation': return '連携再開予定';
      case 'deactivation': return '一時停止予定';
      case 'deletion': return '解除予定';
    }
  }

  scheduleText(): string {
    const a = Formatter.datetime(this.platformOperation.executeTime.toDate());
    switch (this.platformOperation.type) {
      case 'registration': return `「${a}」に連携開始予定`;
      case 'activation': return `「${a}」に連携予定`;
      case 'deactivation': return `「${a}」に連携を一時停止予定`;
      case 'deletion': return `「${a}」に連携を解除し連携データを削除します。ただし、売上に関するデータは保存されます。`;
    }
  }
}
