import React from 'react';
import { Item } from '../../entities';
import styles from '../../styles/components/lists/ItemList.module.scss';

type ItemPart = Omit<Item, 'accountId' | 'storeId' | 'brandId'>;

type Props = {
  items: ItemPart[];
};

export const ItemList: React.FC<Props> = ({ items }: Props) => {
  const sections = buildSections(items);

  return (
    <section className={styles.itemSection}>
      <h3 className={styles.itemSectionTitle}>商品</h3>
      {sections.filter(section => section.single).map(section => (
        <React.Fragment key={`item-list-section-${section.category}`}>
          <h4 className={styles.categoryTitle}><span className={styles.ellipsis}>{section.category}</span></h4>
          <ul className={styles.items}>
            {section.data.map(item => ItemListItem({ item }))}
          </ul>
        </React.Fragment>
      ))}
      <h3 className={styles.itemSectionTitle}>オプション</h3>
      {sections.filter(section => !section.single).map(section => (
        <React.Fragment key={`item-list-section-${section.category}`}>
          <h4 className={styles.categoryTitle}><span className={styles.ellipsis}>{section.category}</span></h4>
          <ul className={styles.items}>
            {section.data.map(item => ItemListItem({ item }))}
          </ul>
        </React.Fragment>
      ))}
    </section>
  );
};

const ItemListItem: React.FC<{ item: ItemPart }> = ({ item }: { item: ItemPart }) =>
  <li className={styles.item} key={`item-list-item-${item.id}`}>
    <p className={styles.itemTitle}><span className={styles.ellipsis}>{item.title}</span></p>
    <p className={styles.itemPrice}>{(item.price === 0 || item.price) ? `¥ ${item.price}` : ''}</p>
  </li>;

const buildSections = (items: ItemPart[]) =>
  Array.from(new Set(items?.map(item => item.categories ?? [])?.reduce(makeFlat, []) ?? []))
    .map(mapItemsToSection(items))
    .filter(sections => sections.data.length > 0);

const mapItemsToSection = (allItems: ItemPart[]) => (category: string): { category: string, data: ItemPart[], single: boolean} => {
  const data = allItems.filter(item => item.categories?.includes(category));
  const single = data.every(item => item.single);
  return { category, data, single };
};

const makeFlat = <T,>(acc: T[], item: T[]) => acc.concat(item);
