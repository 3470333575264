import React from 'react';
import { PlatformDecorator } from '../../decorators';
import { Brand, Platform, Store } from '../../entities';
import styles from '../../styles/components/views/PlatformSummaryView.module.scss';

interface PlatformSummaryViewProps {
  platform?: Pick<Platform, 'type' | 'name'>;
  store?: Pick<Store, 'name'>;
  brand?: Pick<Brand, 'name'>;
}

export const PlatformSummaryView: React.FC<PlatformSummaryViewProps> = (props: PlatformSummaryViewProps) => {
  return props.platform || props.store || props.brand ? (
    <div className={styles.wrapper}>
      {props.platform && (
        <div className={styles.platform}>
          <img src={new PlatformDecorator(props.platform).icon()} />
          <p>{new PlatformDecorator(props.platform).name()}</p>
          <p>{props.platform.name}</p>
        </div>
      )}
      {props.store && (
        <div className={styles.store}>
          <p>店舗名:</p>
          <p>{props.store.name}</p>
        </div>
      )}
      {props.brand && (
        <div className={styles.brand}>
          <p>ブランド名:</p>
          <p>{props.brand.name}</p>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
