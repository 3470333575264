import Link from 'next/link';
import React from 'react';
import styles from '../../styles/components/layouts/Sidebar.module.scss';
import { useAuth } from '../../utils';

interface Props {
  selected: string;
}

const Sidebar: React.FC<Props> = (props: Props) => {
  const auth = useAuth();

  const logout = async () => {
    await auth.logout();
  };

  return (
    <div className={styles.sidebar}>
      <Link href="/">
        <a>
          <img src="/orderly/logo_white.svg" className={styles.logo} />
        </a>
      </Link>
      <ul className={styles.topMenu}>
        <li className={props.selected === '' ? styles.selectedMenu : styles.menu}>
          <Link href="/">
            <div className={styles.link}>
              <img src="/icon/chart.svg" />
              <a>ダッシュボード</a>
            </div>
          </Link>
        </li>
        <li className={props.selected === 'reports' ? styles.selectedMenu : styles.menu}>
          <Link href="/reports">
            <div className={styles.link}>
              <img src="/icon/report.svg" />
              <a>レポート</a>
            </div>
          </Link>
        </li>
        <li className={props.selected === 'orders' ? styles.selectedMenu : styles.menu}>
          <Link href="/orders">
            <div className={styles.link}>
              <img src="/icon/history.svg" />
              <a>注文履歴</a>
            </div>
          </Link>
        </li>
        <li className={props.selected === 'stores' ? styles.selectedMenu : styles.menu}>
          <Link href="/stores">
            <div className={styles.link}>
              <img src="/icon/register.svg" />
              <a>店舗一覧・連携</a>
            </div>
          </Link>
        </li>
        <li className={props.selected === 'pos' ? styles.selectedMenu : styles.menu}>
          <Link href="/pos">
            <div className={styles.link}>
              <img src="/icon/pos.svg" />
              <a>POS連携</a>
            </div>
          </Link>
        </li>
      </ul>
      <ul className={styles.bottomMenu}>
        <li className={props.selected === 'support' ? styles.selectedMenu : styles.menu}>
          <a href="https://faq.orderly.jp/knowledge/%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%AB%E9%80%A3%E7%B5%A1%E3%81%99%E3%82%8B" target="_blank" rel="noreferrer">
            <div className={styles.link}>
              <img src="/icon/question.svg" />
              サポート
            </div>
          </a>
        </li>
        <li className={styles.menu} onClick={logout}>
          <div className={styles.link}>
            <img src="/icon/logout.svg" />
            <a>ログアウト</a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export { Sidebar };
