import React from 'react';
import styles from '../../styles/components/buttons/SkeltonButton.module.scss';

interface Props {
  title: string;
  onClick: () => void;
}

const SkeltonButton: React.FC<Props> = (props: Props) =>
  <button className={styles.button} onClick={props.onClick}>
    {props.title}
  </button>

export { SkeltonButton };
