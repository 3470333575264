import React from 'react';
import styles from '../../styles/components/badges/NewBadge.module.scss';

interface Props {
  color: 'primary' | 'warning' | 'white'
}

export const NewBadge: React.FC<Props> = ({ color }: Props): React.ReactElement =>
  <span className={style(color)}>
    {title(color)}
  </span>

const style = (color: 'primary' | 'warning' | 'white') =>
  ({ primary: styles.primaryBadge, warning: styles.warningBadge, white: styles.whiteBadge })[color]

const title = (color: 'primary' | 'warning' | 'white') =>
  ({ primary: 'New', warning: 'New', white: 'N' })[color]
