import { DeliveryPlatformType } from '../entities';

export class DeliveryPlatformTypeDecorator {
  readonly deliveryPlatformType: DeliveryPlatformType;

  constructor(deliveryPlatformType: DeliveryPlatformType) {
    this.deliveryPlatformType = deliveryPlatformType;
  }

  name(): string {
    switch (this.deliveryPlatformType) {
      case 'boteats':
        return 'Bot Eats';
      case 'anycarry':
        return 'エニキャリ';
      case 'demaecan':
        return '出前館';
      case 'menu':
        return 'menu';
      case 'ubereats':
        return 'Uber Eats';
      case 'wolt':
        return 'Wolt'
    }
  }
}
