import React from 'react';
import styles from '../../styles/components/views/StatisticsView.module.scss';
import { Spinner } from '../layouts/Spinner';

interface Props {
  title: string;
  value: string;
  loading: boolean;
}

export const StatisticsView: React.FC<Props> = ({ title, value, loading }) => {
  return (
    <div className={styles.warpper}>
      {
        loading
          ? <Spinner loading size='contain'/>
          :<>
            <p className={styles.title}>{title}</p>
            <p className={styles.value}>{value}</p>
          </>
      }
    </div>
  );
};
