import Link from 'next/link';
import { KeyboardEvent, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from '../../styles/components/layouts/PageHeader.module.scss';

type Path = {
  name: string;
  path: string;
};

interface Props {
  title: string;
  extra?: React.ReactNode
  paths: Path[];
  suffix?: string;
  onEdited?: (_: string) => void;
}

const PageHeader: React.FC<Props> = (props: Props) => {
  const [editing, setEditing] = useState(false);

  const onClickEdit = () => {
    setEditing(true);
  };

  const onOutsideClick = () => {
    setEditing(false);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      e.preventDefault();
      props.onEdited?.((e.target as HTMLInputElement).value);
      setEditing(false);
    }
  };

  return (
    <header className={styles.header}>
      <ul>
        {props.paths.map((path, i) => (
          <li key={`${path.path}_${i}`}>
            <Link href={`/${[...Array(i + 1)].map((_, n) => props.paths[n].path).join('/')}`}>
              <a>{path.name}</a>
            </Link>
          </li>
        ))}
      </ul>
      {(() => {
        if (editing) {
          return (
            <h2>
              <OutsideClickHandler onOutsideClick={onOutsideClick}>
                <input
                  type="text"
                  defaultValue={props.title}
                  onKeyPress={onKeyPress}
                  size={props.title.length * 3}
                />
              </OutsideClickHandler>
              {props.suffix}
              {props.extra}
            </h2>
          );
        } else if (props.onEdited) {
          return (
            <h2>
              {props.title}
              {props.suffix}{' '}
              <a onClick={onClickEdit}>
                <img src="/icon/pencil.svg" />
              </a>
              {props.extra}
            </h2>
          );
        } else {
          return (
            <h2>
              {props.title}
              {props.suffix}
              {props.extra}
            </h2>
          );
        }
      })()}
    </header>
  );
};

export { PageHeader };
