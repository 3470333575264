import Link from 'next/link';
import React from 'react';
import { If, TableButtonCoordination } from '../../components';
import { PosPlatform, Store } from '../../entities';
import styles from '../../styles/components/lists/StorePosList.module.scss';

interface Props {
  stores: Store[]
  posPlatforms: PosPlatform[]
}

export const StorePosList: React.FC<Props> = ({ stores, posPlatforms }: Props) => {
  const items = stores
    .map(store => ({ store, posPlatforms: posPlatforms.filter(posPlatform => posPlatform.storeId === store.id)}));

  return <ul className={styles.list}>
    {items.map(item => <StorePosListItem {...item} key={`storePosList-${item.store.id}`} />)}
  </ul>;
};

const StorePosListItem = ({ store, posPlatforms }: { store: Store, posPlatforms: PosPlatform[] }) =>
  <li>
    <If condition={posPlatforms.length > 0}>
      <Link href={`/stores/${store.id}/pos/${posPlatforms?.[0]?.type}`}>
        <a>
          <p>{store.name}</p>
          <div className={styles.countWarning}>
            <img src='/icon/right.svg' />
          </div>
        </a>
      </Link>
    </If>
    <If condition={posPlatforms.length === 0}>
      <Link href={`/stores/${store.id}/pos/new`}>
        <a>
          <p>{store.name}</p>
          <div className={styles.countWarning}>
            <TableButtonCoordination onClick={() => undefined} />
          </div>
        </a>
      </Link>
    </If>
  </li>;
