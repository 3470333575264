import { Item, PosItem } from '../../../entities';
import styles from '../../../styles/components/lists/PosItemList.module.scss';
import { If } from '../../If';
import { ItemContent } from './ItemContent';
import { PosItemContent } from './PosItemContent';

type EditProps =
  | {
    editable: true;
    onOpenModal: (item: Item, fulfillment: 'delivery' | 'pick_up') => void;
  }
  | {
    editable: false;
  }

type Props = {
  posItems: PosItem[];
  item: Item;
  useDisplayOnlyItemsWithPriceMismatch: boolean;
  useDisplayOnlyUnselected: boolean;
  usePickupPosItem: boolean;

  editProps: EditProps
}

export const PosItemListItem: React.FC<Props> = ({
  posItems,
  item,
  useDisplayOnlyItemsWithPriceMismatch,
  useDisplayOnlyUnselected,
  usePickupPosItem,
  editProps
}: Props) => {
  const posItem = posItems.find(posItem => posItem.itemIds.includes(item.id));
  const pickupPosItem = posItems.find(posItem => posItem.pickupItemIds?.includes(item.id));
  const hasAssociatingWarning = !posItem || (usePickupPosItem && !pickupPosItem);
  const hasPriceWarning = !hasAssociatingWarning && posItem?.price !== item.price;

  const onOpenModalForPosItem = () => editProps.editable && editProps.onOpenModal(item, 'delivery');
  const onOpenModalForPickupPosItem = () => editProps.editable && editProps.onOpenModal(item, 'pick_up');

  return (
    <>
      <If condition={useDisplayOnlyItemsWithPriceMismatch && hasPriceWarning}>
        <li className={hasAssociatingWarning ? styles.menuWarning : undefined}>
          <ItemContent
            key={`posItemListItem-${item.id}-itemContent`}
            {...{ item, hasAssociatingWarning, usePickupPosItem }} />
          <PosItemContent
            key={`posItemListItem-${item.id}-posItemContent`}
            {...{ editable: editProps.editable, hasPriceWarning, posItems, posItem, pickupPosItem, usePickupPosItem, onOpenModalForPosItem, onOpenModalForPickupPosItem }} />
        </li>
      </If>
      <If condition={useDisplayOnlyUnselected && hasAssociatingWarning}>
        <li className={hasAssociatingWarning ? styles.menuWarning : undefined}>
          <ItemContent
            key={`posItemListItem-${item.id}-itemContent`}
            {...{ item, hasAssociatingWarning, usePickupPosItem }} />
          <PosItemContent
            key={`posItemListItem-${item.id}-posItemContent`}
            {...{ editable: editProps.editable, hasPriceWarning, posItems, posItem, pickupPosItem, usePickupPosItem, onOpenModalForPosItem, onOpenModalForPickupPosItem }} />
        </li>
      </If>
      <If condition={!useDisplayOnlyUnselected && !useDisplayOnlyItemsWithPriceMismatch}>
        <li className={hasAssociatingWarning ? styles.menuWarning : undefined}>
          <ItemContent
            key={`posItemListItem-${item.id}-itemContent`}
            {...{ item, hasAssociatingWarning, usePickupPosItem }} />
          <PosItemContent
            key={`posItemListItem-${item.id}-posItemContent`}
            {...{ editable: editProps.editable, hasPriceWarning, posItems, posItem, pickupPosItem, usePickupPosItem, onOpenModalForPosItem, onOpenModalForPickupPosItem }} />
        </li>
      </If>
    </>
  );
}
