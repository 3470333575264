import { FC } from 'react';
import { default as ReactSelect } from 'react-select';
import styles from '../../styles/components/forms/Select.module.scss';

interface SelectProps {
  title: string;
  options: { value: string | null; label: string }[];
  onChange: (_: string | null) => void;
  value: string;
  message?: string;
}

export const Select: FC<SelectProps> = ({ title, options, onChange, value, message }) => (
  <div className={styles.input}>
    <p>{title}</p>
    <ReactSelect
      inputId="select"
      value={options.find((option) => option.value === value)}
      onChange={(option) => onChange((option && option.value) ?? null)}
      options={options}
      placeholder="選択してください"
      styles={{
        option: (styles) => ({
          ...styles,
          cursor: 'pointer',
        }),
        control: (styles) => ({
          ...styles,
          cursor: 'pointer',
        }),
      }}
    />
    {message && <div className={styles.error}>{message}</div>}
  </div>
);
